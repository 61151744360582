import { useMemo, useState, useEffect } from 'react';

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';

import { Layout } from './components/Layout';
import { PageDefault } from './components/PageDefault';
import LoginPage from './components/Login/LoginPage';
import AppLayout from './components/AppLayout';
import { RedirectLogin } from './components/RedirectLogin';

import { AppContext, ThemeModeContext } from './contexts';
import { routes } from './config';
import { Route as AppRoute } from './types';
import { getAppTheme } from './styles/theme';
import { DARK_MODE_THEME, LIGHT_MODE_THEME } from './utils/constants';

import { useSelector, useDispatch } from 'react-redux';
import { UserState, UserLogin } from './store/user/types';
import { loadState } from './utils/localstorage';

import { userTunk } from './store/user/action';

import { ApplicationState } from './store/index';

import { Link, useHistory } from 'react-router-dom';


import { createBrowserHistory } from 'history';

import configureStore from './configureStore';
import { Provider } from 'react-redux';
import { Dispatch } from 'redux';
import { writeFileSync } from 'fs';

const history = createBrowserHistory();

const initialState: any = {};
const store = configureStore(history, initialState);

function App() {

	const [mode, setMode] = useState<typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME>(LIGHT_MODE_THEME);
	const [valStore, setStore] = useState(false);
	let user = useSelector((state: ApplicationState) => state.user.data);
	let userLocal: UserLogin = loadState();

	const dispatchThunk = useDispatch<Dispatch<any>>();	
	
	useEffect(() => {
		if (!valStore) {
			//if (!['/users'].includes(history.location.pathname)) {
			dispatchThunk(userTunk(userLocal));
			/*  } else {
				console.log(userLocal);
			} */
			setStore(true);
		}
	}, [dispatchThunk, valStore, userLocal]);	

	const themeMode = useMemo(
		() => ({
			toggleThemeMode: () => {
				setMode((prevMode) => (prevMode === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME));
			},
		}),
		[]
	);


	const theme = useMemo(() => getAppTheme(mode), [mode]);
	/* const theme = useMemo(
		() =>
			createTheme({
				typography: {
					// In Chinese and Japanese the characters are usually larger,
					// so a smaller fontsize may be appropriate.
					htmlFontSize: 20,
				},
				palette: {
					mode: 'light',
					primary: {
						main: '#388e3c',
					},
				},
			}),
		[mode]
	); */

	

	const addRoute = (route: AppRoute) => {
		const exactCategoryRoute: any = route.key.split('-');
		if (user?.user?.category) {	

			return (exactCategoryRoute.includes('EXACT') && exactCategoryRoute.includes(user?.user?.category)) ? (
				<Route key={route.key} path={route.path} component={(user.signup || userLocal.signup) ? (route.component || PageDefault) : undefined/*RedirectLogin*/} exact />
			) : undefined;
			
		} else {
			
			if (!exactCategoryRoute.includes('EXACT')) {
				return (
					<Route key={route.key} path={route.path} component={(user.signup || userLocal.signup) ? (route.component || PageDefault) : undefined/*RedirectLogin*/} exact />
				);
			} else {				
				return (exactCategoryRoute.includes('SUPER')&&user.user.super)?(<Route key={route.key} path={route.path} component={(user.signup || userLocal.signup) ? (route.component || PageDefault) : undefined/*RedirectLogin*/} exact />):undefined;
			}
		}		
	};

	return (
		<ThemeModeContext.Provider value={themeMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{/* <Router> */}
				<Switch>
					{user.signup || userLocal.signup ? (
						<Layout>
							{routes.map((route: AppRoute) =>
								route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route)
							).filter((k:any)=>k)}
						</Layout>
					) : (
						<Route key='Login' path='/' component={LoginPage} exact />
					)}
					<Route key='Top' path='*' component={RedirectLogin} exact />
				</Switch>
				{/* </Router> */}
			</ThemeProvider>
		</ThemeModeContext.Provider>
	);
}

export default App;