import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MenuIcon from '@mui/icons-material/Menu';
import DocumentationJson from './ApiDocumentationJson'
import DocumentationCode from './ApiDocumentationCode';

import php from '../../../assets/images/php.png';
import node from '../../../assets/images/node.png';
import python from '../../../assets/images/python.png';
import curl from '../../../assets/images/curl.png';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Collapse from '@mui/material/Collapse';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Tooltip from '@mui/material/Tooltip';

import MenuBookIcon from '@mui/icons-material/MenuBook';

import DiccionarioSingIn from '../Diccionario/Singin';
import DiccionarioImages from '../Diccionario/Images';
import DiccionarioSheets from '../Diccionario/Sheets';
import DiccionarioSheetsJson from '../Diccionario/SheetsJson';
import DiccionarioProducts from '../Diccionario/Products';
import DiccionarioPrecios from '../Diccionario/Precios';
import DiccionarioFabricantes from '../Diccionario/Fabricantes';
import DiccionarioCategorias from '../Diccionario/Categorias';
import DiccionarioBrsLocations from '../Diccionario/BrsLocations';
import DiccionarioOrders from '../Diccionario/Orders';
import DiccionarioShipping from '../Diccionario/Shipping';
import DiccionarioCountryCode from '../Diccionario/CountryCode';

import { useSelector, useDispatch } from 'react-redux';
import { UserState, UserLogin } from '../../../store/user/types';
import { loadState } from '../../../../src/utils/localstorage';

import { ApplicationState } from '../../../store/index';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children?: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface ItemProps {
	getValues: Function;
}

interface ApiItemProps {	
	props: ItemProps;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export default function ApiDiccionario() {
	const [open, setOpen] = React.useState(false);
	let user = useSelector((state: ApplicationState) => state.user.data);
	const [openLogin, setOpenLogin] = React.useState(false);

	let userLocal: UserLogin = loadState();
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [value, setValue] = React.useState(0);

	const handleClickOpenLogin = () => {
		setOpenLogin(true);
	};
	const handleCloseLogin = () => {
		setOpenLogin(false);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};	

	const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);

	const handleOpen = (clickedIndex:any) => {
		if (isOpenCollapse === clickedIndex) {
			setIsOpenCollapse(null);
		} else {
			setIsOpenCollapse(clickedIndex);
			setOpenToolTip(true);
		}
	};

	const [openToolTip, setOpenToolTip] = React.useState(false);


	return (
		<React.Fragment>
			{user.signup || userLocal.signup ? (
				<>
					<MenuBookIcon onClick={handleClickOpen} />
					<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
						<Box sx={{ flexGrow: 1 }}>
							<AppBar position='static'>
								<Toolbar variant='dense'>
									<IconButton
										size='large'
										edge='start'
										color='inherit'
										onClick={handleClose}
										aria-label='menu'
										sx={{ mr: 2 }}
									>
										<CloseIcon />
									</IconButton>
									<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
										Diccionario de Datos
									</Typography>
								</Toolbar>
							</AppBar>
						</Box>

						<List>
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(0)}
								/>
								<ListItemText
									onClick={() => handleOpen(0)}
									sx={{ pl: 0, cursor: 'pointer' }}
									primary='POST /signin'
									secondary='Obtención de acceso, para consumir recursos [Duración del token 60 minutos]'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 0} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioSingIn />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(1)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(1)}
									primary='GET /api/image'
									secondary='Obtención de imagen, tokenAppContents, consumir la url presentada en api/products en campo "images"'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 1} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioImages />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(2)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(2)}
									primary='GET /api/sheets'
									secondary='Obtención de ficha técnica, tokenAppContents, consumir la url presentada en api/products en campo "sheet"'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 2} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioSheets />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(3)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(3)}
									primary='GET /api/sheets/json'
									secondary='Obtención de ficha técnica formato Json, tokenAppContents, consumir la url presentada en api/products en campo "sheetJson"'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 3} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioSheetsJson />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(4)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(4)}
									primary='POST /api/products'
									secondary='Obtención de productos, Si "report" existe, se omite el segmentado. Max respuesta 300 items por solicitud para segmentado'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 4} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioProducts />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 5 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(5)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(5)}
									primary='POST /api/prices'
									secondary='Obtención de precios, Si "report" existe, se omite el segmentado. Max respuesta 300 items por solicitud para segmentado'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 5} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioPrecios />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 6 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(6)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(6)}
									primary='POST /api/manufacturer'
									secondary='Obtención de fabricantes, Max respuesta 300 items por solicitud'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 6} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioFabricantes />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 7 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(7)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(7)}
									primary='POST /api/categories'
									secondary='Obtención de categorias, Max respuesta 300 items por solicitud'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 7} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioCategorias />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 8 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(8)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(8)}
									primary='GET /api/brslocations'
									secondary='Obtención de Localidades para almacenes'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 8} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioBrsLocations />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 9 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(9)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(9)}
									primary='PUT /api/order'
									secondary='Colocación de orden en ingram'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 9} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioOrders />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 10 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(10)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(10)}
									primary='POST /api/shipping'
									secondary='Obtención Costo de Envio'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 10} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioShipping />} />
								</ListItem>
							</Collapse>
							<Divider />
							<ListItem>
								<ListItemText
									sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
									primary={isOpenCollapse === 11 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									onClick={() => handleOpen(11)}
								/>
								<ListItemText
									sx={{ pl: 0, cursor: 'pointer' }}
									onClick={() => handleOpen(11)}
									primary='GET /api/countrycode'
									secondary='Obtención Codigos de Ciudad'
								/>
							</ListItem>
							<Collapse in={isOpenCollapse === 11} timeout='auto' unmountOnExit>
								<ListItem>
									<ListItemText sx={{ pl: 4 }} primary={<DiccionarioCountryCode />} />
								</ListItem>
							</Collapse>
						</List>
					</Dialog>
				</>
			) : (
				<>
					<MenuBookIcon onClick={handleClickOpenLogin} />
					<Dialog
						open={openLogin}
						onClose={handleCloseLogin}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogTitle id='alert-dialog-title'>{`Login (Solicita tus accesos)`}</DialogTitle>
						<DialogContent>
							<Box sx={{ width: '100%', typography: 'body1' }}>
								{`Solicita tus accesos`}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseLogin}>Cerrar</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</React.Fragment>
	);
}