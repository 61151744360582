import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Tabs from '@mui/material/Tabs';

import { useSelector, useDispatch } from 'react-redux';
import { UserState, UserLogin } from '../../../store/user/types';
import { loadState } from '../../../../src/utils/localstorage';

import { ApplicationState } from '../../../store/index';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface ItemProps {
	data: any;
	header: any;
	title: any;
	label: any;
}

export default function ApiDocumentationJson({ data, header, title, label }: ItemProps) {
	const [open, setOpen] = React.useState(false);
	const [openLogin, setOpenLogin] = React.useState(false);
	const [dataCode, setDataCode] = React.useState(false);
	let user = useSelector((state: ApplicationState) => state.user.data);
	let userLocal: UserLogin = loadState();
	const handleClickOpen = (data: any) => {
		setOpen(true);
		setDataCode(data);
	};

	const handleClickOpenLogin = () => {
		setOpenLogin(true);
	};
	const handleCloseLogin = () => {
		setOpenLogin(false);
	};
	const handleClose = () => {
		setOpen(false);
	};

	

	const [value, setValue] = React.useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const codeString = '(num) => num + 1';

	return (
		<React.Fragment>
			{user.signup || userLocal.signup ? (
				<>
					<Button style={{ backgroundColor: 'transparent' }} onClick={() => handleClickOpen(data)}>
						<img src={label} alt={''} style={{ margin: '10px', width: 40 }} />
					</Button>

					<Dialog
						fullWidth={true}
						maxWidth={'xl'}
						open={open}
						onClose={handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogTitle id='alert-dialog-title'>{header}</DialogTitle>
						<DialogContent>
							<Box sx={{ width: '100%', typography: 'body1' }}>
								<TabContext value={value}>
									<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
										<Tabs
											value={value}
											onChange={handleChange}
											variant='scrollable'
											scrollButtons='auto'
											aria-label='scrollable auto tabs example'
										>
											{title.map((t: any, index: any) => (
												<Tab key={index} label={t} value={String(index + 1)} />
											))}
										</Tabs>
									</Box>
									{data.map((t: any, index: any) => (
										<TabPanel key={index} value={String(index + 1)}>
											{/* 
											<div
												style={{ fontSize: '12px' }}
												dangerouslySetInnerHTML={{
													__html: t,
												}}
											></div> 
											*/}
											<div>
												<SyntaxHighlighter language='php' style={docco}>
													{t?.replaceAll('<pre>', '')?.replaceAll('</pre>', '')||''}
												</SyntaxHighlighter>
											</div>
										</TabPanel>
									))}
								</TabContext>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cerrar</Button>
						</DialogActions>
					</Dialog>
				</>
			) : (
				<>
					<Button style={{ backgroundColor: 'transparent' }} onClick={() => handleClickOpenLogin()}>
						<img src={label} alt={''} style={{ margin: '10px', width: 40 }} />
					</Button>
					<Dialog
						open={openLogin}
						onClose={handleCloseLogin}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogTitle id='alert-dialog-title'>{`Login (Solicita tu acceso)`}</DialogTitle>
						<DialogContent>
							<Box sx={{ width: '100%', typography: 'body1' }}>{`Solicita tu acceso`}</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseLogin}>Cerrar</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</React.Fragment>
	);
}
