import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { useSelector, useDispatch } from 'react-redux';
import { UserState, UserLogin } from '../../../store/user/types';
import { loadState } from '../../../../src/utils/localstorage';

import { ApplicationState } from '../../../store/index';

import { styled, Box } from '@mui/material';


interface ItemProps {
	data: string;
	title: string;
	label: string;
	diccionario?: any;
}

function syntaxHighlight(json:any) {
	if (!json) return ''; //no JSON from response
	json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
	return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
		function (match:any) {
			var cls = 'number';
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = 'key';
				} else {
					cls = 'string';
				}
			} else if (/true|false/.test(match)) {
				cls = 'boolean';
			} else if (/null/.test(match)) {
				cls = 'null';
			} 
			return '<span class="' + cls + '">' + match + '</span>';
		}
	);
}

export default function ApiDocumentationJson({ data, title, label, diccionario }: ItemProps) {
	const [open, setOpen] = React.useState(false);
	const [openLogin, setOpenLogin] = React.useState(false);
	let user = useSelector((state: ApplicationState) => state.user.data);
	let userLocal: UserLogin = loadState();
	const handleClickOpenLogin = () => {
		setOpenLogin(true);
	};
	const handleCloseLogin = () => {
		setOpenLogin(false);
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			{user.signup || userLocal.signup ? (
				<>
					<Button variant='outlined' onClick={handleClickOpen}>
						{label}
					</Button>
					<Dialog
						fullWidth={true}
						maxWidth={'xl'}
						open={open}
						onClose={handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
						<DialogContent>
							{/* <DialogContentText
							id='alert-dialog-description'
							dangerouslySetInnerHTML={{
								__html: `<pre>${JSON.stringify(JSON.parse(data), null, 2)}</pre>`,
							}}
						></DialogContentText>
						<div id='alert-dialog-diccionario'>{diccionario}</div> */}
							<Box sx={{ flexGrow: 1, fontSize: '12px' }}>
								<Grid container spacing={2} columns={16}>
									<Grid
										style={{ overflow: 'auto', maxHeight: '450px', maxWidth: '390px', scrollbarWidth: 'thin' }}
										item
										xs={4}
									>
										<pre>
											<LayoutWrapper
												style={{backgroundColor:'white',outline: '1px solid #ccc;', padding: '5px;',margin: '15px;'}}
												dangerouslySetInnerHTML={{
													__html: syntaxHighlight(JSON.stringify(JSON.parse(data), undefined, 4)),
												}}
											/>
										</pre>
									</Grid>
									<Grid item xs={12} sx={{ marginTop: '10px' }}>
										{diccionario}
									</Grid>
								</Grid>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cerrar</Button>
						</DialogActions>
					</Dialog>
				</>
			) : (
				<>
					<Button variant='outlined' onClick={() => handleClickOpenLogin()}>
						{label}
					</Button>
					<Dialog
						open={openLogin}
						onClose={handleCloseLogin}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogTitle id='alert-dialog-title'>{`Login (Solicita tu acceso)`}</DialogTitle>
						<DialogContent>
							<Box sx={{ width: '100%', typography: 'body1' }}>{`Solicita tu acceso`}</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseLogin}>Cerrar</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</React.Fragment>
	);
}


const LayoutWrapper = styled('div')`
	.string {
		color: green;
	}
	.number {
		color: darkorange;
	}
	.boolean {
		color: blue;
	}
	.null {
		color: magenta;
	}
	.key {
		color: red;
	}
	
`;