import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MenuIcon from '@mui/icons-material/Menu';
import DocumentationJson from './ApiDocumentationJson'
import DocumentationCode from './ApiDocumentationCode';

import php from '../../../assets/images/php.png';
import node from '../../../assets/images/node.png';
import python from '../../../assets/images/python.png';
import curl from '../../../assets/images/curl.png';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Collapse from '@mui/material/Collapse';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Tooltip from '@mui/material/Tooltip';

import DialogDiccionario from './ApiDiccionario';

import DiccionarioSingIn from '../Diccionario/Singin';
import DiccionarioImages from '../Diccionario/Images';
import DiccionarioSheets from '../Diccionario/Sheets';
import DiccionarioSheetsJson from '../Diccionario/SheetsJson';
import DiccionarioProducts from '../Diccionario/Products';
import DiccionarioPrecios from '../Diccionario/Precios';
import DiccionarioFabricantes from '../Diccionario/Fabricantes';
import DiccionarioCategorias from '../Diccionario/Categorias';
import DiccionarioBrsLocations from '../Diccionario/BrsLocations';
import DiccionarioOrders from '../Diccionario/Orders';
import DiccionarioShipping from '../Diccionario/Shipping';
import DiccionarioCountryCode from '../Diccionario/CountryCode';

import MenuBookIcon from '@mui/icons-material/MenuBook';

import NavigationIcon from '@mui/icons-material/Navigation';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';

import dis1 from '../../../assets/tour/Diapositiva1.png';
import dis2 from '../../../assets/tour/Diapositiva2.png';
import dis3 from '../../../assets/tour/Diapositiva3.png';
import dis4 from '../../../assets/tour/Diapositiva4.png';
import dis5 from '../../../assets/tour/Diapositiva5.png';
import dis6 from '../../../assets/tour/Diapositiva6.png';
import dis7 from '../../../assets/tour/Diapositiva7.png';
import dis8 from '../../../assets/tour/Diapositiva8.png';
import dis9 from '../../../assets/tour/Diapositiva9.png';
import dis10 from '../../../assets/tour/Diapositiva10.png';
import dis11 from '../../../assets/tour/Diapositiva11.png';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children?: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface ItemProps {
	getValues: Function;
}

interface ApiItemProps {	
	props: ItemProps;	
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

let links = ["./txtCode/phpConnect.txt","./txtCode/nodeConnect.txt"]

export default function ApiDocumentation({ props }: ApiItemProps) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		props?.getValues(true);
	};

	const handleClose = () => {
		setOpen(false);
		props?.getValues(false);
	};

	const [value, setValue] = React.useState(0);

	

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};	

	const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);

	const handleOpen = (clickedIndex:any) => {
		if (isOpenCollapse === clickedIndex) {
			setIsOpenCollapse(null);
		} else {
			setIsOpenCollapse(clickedIndex);
			setOpenToolTip(true);
		}
	};

	const [openToolTip, setOpenToolTip] = React.useState(false);

	

	return (
		<React.Fragment>
			<>
				<Fab color='primary' variant='extended' onClick={handleClickOpen}>
					<NavigationIcon sx={{ mr: 1 }} />
					Admin
				</Fab>
				<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
					{/* <Box sx={{ flexGrow: 1 }}>
							<AppBar sx={{ position: 'static' }}>
								<Toolbar>
									<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
										<CloseIcon />
									</IconButton>
									
									<Button color='inherit' onClick={handleClose}>
										Web Services
									</Button>
									<Button color='inherit'>Diccionario</Button>
								</Toolbar>
							</AppBar>
						</Box> */}
					<Box sx={{ flexGrow: 1 }}>
						<AppBar position='static'>
							<Toolbar variant='dense'>
								<IconButton
									size='large'
									edge='start'
									color='inherit'
									onClick={handleClose}
									aria-label='menu'
									sx={{ mr: 2 }}
								>
									<CloseIcon />
								</IconButton>
								<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
									Admin
								</Typography>
							</Toolbar>
						</AppBar>
					</Box>

					<List onMouseOver={() => setOpenToolTip(true)} onMouseOut={() => setOpenToolTip(false)}>
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis1} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis2} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis3} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis4} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis5} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis6} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis7} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis8} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis9} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis10} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ pl: 0 }}
								primary={
									<React.Fragment>
										<div
											style={{
												overflowX: 'scroll',
												scrollbarWidth: 'none',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img src={dis11} alt={''} style={{ margin: '10px', width: '80%' }} />
										</div>
									</React.Fragment>
								}
							/>
						</ListItem>
					</List>
				</Dialog>
			</>
		</React.Fragment>
	);
}