import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Link as MuiLink,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { literal, object, string, TypeOf, boolean, nullable } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from './FormInput';

import styled from '@emotion/styled';

import { UserLogin } from "../../store/user/types";
import { getLogin } from "../../store/user/action";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store/index';

import FormHelperText from '@mui/material/FormHelperText';

import React, { useState } from "react";

import Switch from '@mui/material/Switch';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { blue, yellow, red } from '@mui/material/colors';

import { loadState } from '../../utils/localstorage';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LinkMUI from '@mui/material/Link';

import mia from '../../assets/images/logo-bdi.png';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import DialogDocumentation from '../../components/Admin/Dialogs/ApiDocumentation'
import Tour from '../../components/Admin/Dialogs/Tour';
import Support from '../../components/Admin/Dialogs/Support';


import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import SandBoxAccess from '../Admin/Dialogs/SandBoxAccess'
import Tooltip from '@mui/material/Tooltip';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';


// 👇 Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// 👇 Styled Material UI Link Component
export const OauthMuiLink = styled(MuiLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: #f5f6f7;
  border-radius: 1;
  padding: 0.6rem 0;
  column-gap: 1rem;
  text-decoration: none;
  color: #393e45;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
  }
`;

// 👇 Login Schema with Zod
const loginSchema = object({
	email: string().min(1, 'Email is required').email('Email is invalid'),
	password: string()
		.min(1, 'Password is required')
		.min(4, 'Password must be more than 8 characters')
		.max(32, 'Password must be less than 32 characters'),
	//persistUser: literal(true).optional(),
});

// 👇 Infer the Schema to get the TS Type
type ILogin = TypeOf<typeof loginSchema>;

interface propsFromComponent {
  user: UserLogin;
}

interface propsFromDispatch {
  getLogin: (user: any) => any;
}

type Props = propsFromComponent & propsFromDispatch;

function Copyright(props: any) {
	return (
		<Typography variant='body2' color='text.secondary' align='center' {...props}>
			{'Copyright © '}
			<LinkMUI color='inherit' href='https://www.bluediamondinnovation.com'>
				Blue Diamond Innovation
			</LinkMUI>
			{/* <LinkMUI color='inherit' href='#'>
				Qservices
			</LinkMUI> */}{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}
const defaultTheme = createTheme();
// TODO remove, this demo shouldn't need to reset the theme.
/* const defaultTheme = createTheme({
	typography: {
		// In Chinese and Japanese the characters are usually larger,
		// so a smaller fontsize may be appropriate.
		htmlFontSize: 20,
	},
	palette: {
		mode: 'light',
		primary: {
			main: '#388e3c',
		},
	},
}); */

const LoginPage: React.FC<Props> = ({  /*item,*/  getLogin }) => {
const navigate = useHistory();
const [authing, setAuthing] = useState(false);	
	
const [input, setInput] = React.useState({
 showPassword: false,
});
	
const [openToolTip, setOpenToolTip] = React.useState(true);
  
/* const [sandbox, setSandBox] = useState({
  	email: '',
	password: '',
}); */
	  
  const { errors, loading=true } = useSelector((state: ApplicationState) => state.user);

  const userLogin = async (user: any) => {
	  await getLogin(user);
	  if (typeof errors?.code === undefined) {
		  navigate.push('/admin');
	  }
	};
	
  let defaultValues: ILogin = {
    email: "",
	  password: "",
	};
	
	const handleClickShowPassword = () => {
		setInput({
			...input,
			showPassword: !input.showPassword,
		});
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

  // 👇 The object returned from useForm Hook
  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  // 👇 Submit Handler
	const onSubmitHandler: SubmitHandler<ILogin> = (values: ILogin) => {
    	userLogin(values);
	};
	
	const theme = createTheme({
		palette: {
			primary: blue,
			secondary: yellow,
		},
	});

	/* const sandbox = (email: any, password: any) => {
		console.log(email)
		defaultValues.email=email;
	} */
  	// 👇 JSX to be rendered
	return (
		<ThemeProvider theme={defaultTheme}>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar position='static'>
					<Toolbar variant='dense'>
						<Tooltip arrow open={openToolTip} title={'Click Documentación Api'}>
							<IconButton edge='start' color='inherit' aria-label='menu' sx={{ mr: 2 }}>
								<DialogDocumentation props={{ getValues: (values: any) => setOpenToolTip(!values) }} />
							</IconButton>
						</Tooltip>
						<Typography variant='h6' color='inherit' component='div'>
							API Integración con Ingram
						</Typography>
					</Toolbar>
				</AppBar>
			</Box>
			<Box
				sx={{
					margin: 0,
					top: 60,
					left: 'auto',
					bottom: 20,
					right: 20,
					position: 'fixed',
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
			>
				<Support />
			</Box>
			<Box
				sx={{
					margin: 0,
					top: 'auto',
					left: 'auto',
					bottom: 20,
					right: 20,
					position: 'fixed',
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
			>
				<Tour props={{ getValues: (values: any) => setOpenToolTip(!values) }} />
			</Box>
			<Container component='main' maxWidth='xs'>
				<FormProvider {...methods}>
					<CssBaseline />
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<img
							src={mia}
							alt={''}
							style={{ /* borderRadius: '20px' ,*/ width: 256 }} /* style={{ borderRadius: '20px', width: 128 }} */
						/>
						{/* <Typography style={{ fontSize: '12px', color:'green', textShadow: 'black 0 1px' }}>qservicesinnovation@gmail.com</Typography> */}
						<Box component='form' onSubmit={methods.handleSubmit(onSubmitHandler)} noValidate sx={{ mt: 1 }}>
							<FormInput
								margin='normal'
								required
								fullWidth
								id='email'
								label='Email Address'
								name='email'
								autoComplete='email'
								/* onChange={(e: any) => {
									setSandBox({ ...sandbox, email: e.target.value });
								}}
								value={sandbox.email} */
							/>
							<FormInput
								margin='normal'
								required
								fullWidth
								name='password'
								label='Password'
								type={input.showPassword ? 'text' : 'password'}
								id='password'
								autoComplete='current-password'
								/* onChange={(e: any) => {
									setSandBox({ ...sandbox, password: e.target.value });
								}}
								value={sandbox.password} */
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{input.showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{/* <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='Remember me' /> */}
							{/* <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button> */}

							<LoadingButton
								type='submit'
								loading={loading}
								loadingPosition='start'
								startIcon={<LockOpenIcon />}
								fullWidth
								variant='contained'
								sx={{ mt: 3, mb: 2 }}
							>
								Login
							</LoadingButton>

							{errors?.code && (
								<FormHelperText
									sx={{
										fontSize: '0.8rem',
										fontWeight: 400,
										color: { xs: 'red', md: 'red' },
									}}
								>
									{/* {`${errors?.message}: soporte, envia correo a: qservicesinnovation@gmail.com`} */}
									{`${errors?.message}`}
								</FormHelperText>
							)}

							{/* <Grid container>
								<Grid item xs>
									<LinkMUI href='#' variant='body2'>
										Forgot password?
									</LinkMUI>
								</Grid>
								<Grid item>
									<LinkMUI href='#' variant='body2'>
										{"Don't have an account? Sign Up"}
									</LinkMUI>
								</Grid>
							</Grid> */}
							<Grid container>
								<Grid item xs>
									<>Sandbox:</>
									<SandBoxAccess />
									{/* <LinkMUI
										href='#'
										variant='body2'
										onClick={() => {
											setSandBox({ ...sandbox, email: 'algo@emailco.com' });
										}}
									>
										Colombia
									</LinkMUI> */}
								</Grid>
							</Grid>
						</Box>
					</Box>
					<Copyright sx={{ mt: 8, mb: 4 }} />
				</FormProvider>
			</Container>
		</ThemeProvider>
	);
};

//const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getLogin: (user: any) => dispatch(getLogin(user)),
  };
};

export default connect(null/* mapStateToProps */, mapDispatchToProps)(LoginPage);